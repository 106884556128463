@tailwind base;
@tailwind components;
@tailwind utilities;

.blog-title {
    font-family: 'Titillium Web', sans-serif;
}

img {
    width: 100%;
}
